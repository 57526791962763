// Original: https://dribbble.com/shots/5708399-Christmas-Collisions
// By: 𝔅𝔢𝔰𝔱𝔖𝔢𝔯𝔳𝔢𝔡𝔅𝔬𝔩𝔡 @bstsrvdbld
//https://wonderlandams.com/play/balloon
//https://codesandbox.io/p/sandbox/balloon-pop-game-w-threejs-536w1
//https://alexanderperrin.com.au/triangles/ballooning/

import * as THREE from "three"
import { useRef } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { Environment, useGLTF } from "@react-three/drei"
import { EffectComposer, N8AO } from "@react-three/postprocessing"
import { BallCollider, Physics, RigidBody, MeshCollider } from "@react-three/rapier"
import { Underlay, Overlay } from "./OverLays"

const heartShape = new THREE.Shape();

heartShape.moveTo(25, 25);
heartShape.bezierCurveTo(25, 25, 20, 0, 0, 0);
heartShape.bezierCurveTo(- 30, 0, - 30, 35, - 30, 35);
heartShape.bezierCurveTo(- 30, 55, - 10, 77, 25, 95);
heartShape.bezierCurveTo(60, 77, 80, 55, 80, 35);
heartShape.bezierCurveTo(80, 35, 80, 0, 50, 0);
heartShape.bezierCurveTo(35, 0, 25, 25, 25, 25);

const extrudeSettings = {
  depth: 8,
  bevelEnabled: true,
  bevelSegments: 2,
  steps: 2,
  bevelSize: 1,
  bevelThickness: 1
};

const heartGeometry = new THREE.ExtrudeGeometry(heartShape, extrudeSettings);
// Credit - https://codesandbox.io/p/sandbox/bestservedbold-christmas-baubles-zxpv7
//https://tron.network/
THREE.ColorManagement.legacyMode = false
const baubleMaterial = new THREE.MeshLambertMaterial({ color: "#A51B20", emissive: "#A51B20" })
const redBalloonMaterial = new THREE.MeshStandardMaterial({
  metalness: 0.75,
  roughness: 0.15,
  color: "#A51B20",
  emissive: "#A51B20",
  envMapIntensity: 1,
  transparent: true,
  opacity: 0.9
});
const greyBalloonMaterial = new THREE.MeshStandardMaterial({
  metalness: 0.75,
  roughness: 0.15,
  color: "#A51B20",
  emissive: "#c9b6a9",
  envMapIntensity: 1,
  transparent: true,
  opacity: 0.9
})
const sphereGeometry = new THREE.SphereGeometry(1, 28, 28)
const _1sphereGeometry = new THREE.TetrahedronGeometry(1, 0);
const baubles = [...Array(6)].map(() => ({ scale: [0.54, 0.52, 0.50, 0.48, 0.46][Math.floor(Math.random() * 5)] }))
const capMaterial = new THREE.MeshStandardMaterial({ metalness: 1, roughness: 0.1, color: "#deb67d", emissive: "#deb67d", envMapIntensity: 0.1,  refractionRatio: 0.95 })

function Bauble({ vec = new THREE.Vector3(), scale, r = THREE.MathUtils.randFloatSpread, index }) {
  const { nodes } = useGLTF("/cap.glb");
  const { nodes: balloonNodes } = useGLTF("/ballon.glb");
  console.log({ balloonNodes, nodes })
  const api = useRef();

  useFrame((state, delta) => {
    delta = Math.min(0.1, delta)
    api.current.applyImpulse(
      vec
        .copy(api.current.translation())
        .normalize()
        .multiply({ x: -50 * delta * scale, y: -150 * delta * scale, z: -50 * delta * scale }),
    )
  })
  return (
    <RigidBody linearDamping={0.75} angularDamping={0.15} friction={0.2} position={[r(20), r(20) - 25, r(20) - 10]} ref={api} colliders={false} dispose={null}>
      <MeshCollider type="trimesh">
        <mesh
          castShadow scale={.05 * scale}
          position={[0, 0, 0]}
          geometry={balloonNodes.Balloon.geometry}
          material={index == 0 ? redBalloonMaterial : greyBalloonMaterial} />
      </MeshCollider>
    </RigidBody>
  )
}

function Pointer({ vec = new THREE.Vector3() }) {
  const ref = useRef()
  useFrame(({ mouse, viewport }) => {
    vec.lerp({ x: (mouse.x * viewport.width) / 2, y: (mouse.y * viewport.height) / 2, z: 0 }, 0.2)
    ref.current?.setNextKinematicTranslation(vec)
  })
  return (
    <RigidBody position={[100, 100, 100]} type="kinematicPosition" colliders={false} ref={ref}>
      <mesh castShadow receiveShadow scale={0.05} geometry={sphereGeometry} material={capMaterial} />
      <BallCollider args={[2]} />
    </RigidBody>
  )
}

export const App = () => (
  <div style={{position: "relative"}}>
   <Underlay />
    <Overlay />
  <Canvas
    shadows
    gl={{ alpha: true, stencil: false, depth: false, antialias: false }}
    camera={{ position: [0, 0, 20], fov: 32.5, near: 1, far: 100 }}
    onCreated={(state) => (state.gl.toneMappingExposure = 1.5)}>
    <ambientLight intensity={0.1} />
    <spotLight intensity={0.1} position={[20, 20, 25]} penumbra={0.1} angle={0.2} color="white" castShadow shadow-mapSize={[512, 512]} />
    <directionalLight position={[0, 5, -4]} intensity={0.4} color="#fff" />
    <directionalLight position={[0, -15, -0]} intensity={0.4} color="#fff" />
    <Physics gravity={[0, 0, 0]}>
      <Pointer />
      {baubles.map((props, i) => <Bauble key={i} {...props} index={i} />) /* prettier-ignore */}
    </Physics>
    <Environment
      intensity={0.1}
      files="/adamsbridge.hdr" />
    <EffectComposer disableNormalPass>
      <N8AO color="#A51B20" aoRadius={2} intensity={1} />
    </EffectComposer>
  </Canvas>
  </div>
)
