import * as React from "react"
const XIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g fill="#2E2E2E" clipPath="url(#a)">
      <path d="M7.08.415C4.447.69 2.092 2.425 1.02 4.88a7.549 7.549 0 0 0-.314 5.09c.605 2.095 2.17 3.865 4.195 4.75 1.585.69 3.41.804 5.09.315 2.515-.725 4.495-2.79 5.145-5.355C16.15 5.66 13.66 1.55 9.63.59A7.33 7.33 0 0 0 7.08.415Zm.1 4.645c.64.85 1.18 1.55 1.2 1.55.02 0 .686-.7 1.47-1.55l1.436-1.55h.815l-.175.185c-.1.104-.75.81-1.445 1.565a126.87 126.87 0 0 1-1.51 1.625l-.24.255 1.875 2.495c1.03 1.375 1.875 2.514 1.875 2.535 0 .025-.615.04-1.36.04l-1.365-.005-1.27-1.7C7.61 9.33 7.2 8.825 7.16 8.855c-.03.025-.74.79-1.58 1.7L4.056 12.21H3.67c-.215 0-.39-.01-.39-.025 0-.015.795-.886 1.765-1.93C6.01 9.21 6.81 8.335 6.82 8.32c.005-.02-.79-1.105-1.765-2.41C4.08 4.61 3.28 3.535 3.28 3.524c0-.01.615-.014 1.37-.014h1.37l1.16 1.55Z" />
      <path d="M4.514 4.18c.02.034 1.28 1.724 2.79 3.744l2.75 3.68.614.005c.38 0 .61-.02.6-.05-.004-.025-1.26-1.714-2.78-3.75l-2.77-3.7h-.62c-.514 0-.62.01-.584.07Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.379.36h15v15h-15z" />
      </clipPath>
    </defs>
  </svg>
)
export default XIcon
